import React, {useContext, useEffect, useRef, useState} from 'react';
import {DataScroller} from "primereact/datascroller";
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";
import fetchPois from "../../../../api/places/fetchPois";
import PlacesGridCard from "./placesGridCard";
import PlaceModalParent from "../../modals/placeModal/placeModalParent";
import {Toast} from "primereact/toast";
import postDeletePoi from "../../../../api/places/postDeletePoi";
import postAddPoi from "../../../../api/places/postAddPoi";
import {TOAST_DURATION} from "../../../../Constants";
import {addData, getStoreData, initDB} from "../../../../functions/db";
import {Dropdown} from "primereact/dropdown";
import {deepCompare} from "../../../../functions/deepCompare";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationPlus} from "@fortawesome/pro-regular-svg-icons"
import {Button} from "primereact/button";
import NoDataMessage from "../grid/noDataMessage";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {catOptions} from "./catOptions";

const Places = ({}) => {


    //todo radius is 200m


    const gridItem = {
        border: 'solid 1px var(--surface-border)',
        borderRadius: '5px',
        padding: '10px 0 10px 0!important',
        // boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)'
    }

    const Title = {
        fontWeight: 'bold',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
    }

    const TitleContainer = {
        display: 'flex',
        justifyContent: 'space-between',
        // padding: '5px',
        borderBottom: 'solid 1px var(--surface-border)',
        borderLeft: 'none',
        height: '50px'
    }






    const {winWidth, fleets} = useContext(indexContext);
    const {vehicles, toast} = useContext(mainContext);

    const [fleet, setFleet] = useState(fleets?.length === 1 ? fleets[0] : null);
    const [gridData, setGridData] = useState([]);
    const [showPlaceModal, setShowPlaceModal] = useState(false);
    const [placeSelected, setPlaceSelected] = useState();
    const [refreshPois, setRefreshPois] = useState(true);
    const [placeFilter, setPlaceFilter] = useState();
    const [mounted, setMounted] = useState(false)

    const handleRefreshPois = (val) => setRefreshPois(val);
    const handleShowPlaceModal = (val) => setShowPlaceModal(val);

    const openShowPlaceModal = async (val) => {
        setPlaceSelected(val)
        setShowPlaceModal(true);
    };


    const handleDeletePoi = async (val) => {
        await postDeletePoi(val);
        toast.current.show({ severity: 'info', summary: 'All done', detail: 'POI successfully deleted', life: TOAST_DURATION });
        await getPois()
    }

    const getPois = async (fleet) => {

        let data = {};
        if (fleet) data.fleetIds = [fleet?.fleetId]
        let r = await fetchPois(data);

        if (placeFilter){
            r = await r.filter(p => p.cat === placeFilter?.display)
        }
        r = await r?.filter(p => p?.fleetId !== 'deleted_from_poi')

        setGridData(r);
    }

    useEffect(async () => {

        if (!showPlaceModal && refreshPois && mounted){
            await getPois(fleet);
        }
    }, [showPlaceModal, refreshPois]);
    //
    useEffect(async () => {
        if (mounted) await getPois(fleet);
    }, [fleet, placeFilter, mounted]);


    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])






    const GridRow = (data) => {

        return (
            <div style={{width:'100%'}} key={data?._id} id={data.name + '_' + data.fleetId} className="gridCard">
                <div style={{padding: '15px'}}>
                    <div className="surface-card" style={gridItem}>
                        <PlacesGridCard place={data} openShowPlaceModal={openShowPlaceModal} handleDeletePoi={handleDeletePoi}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div style={TitleContainer}>
                <div style={Title}>
                    <span style={{marginLeft: '10px'}}>Places of Interest</span>
                </div>
            </div>

            <div style={{height: '50px', borderBottom: '1px solid var(--surface-border)', display:'flex', justifyContent: 'space-between',
                alignItems: 'center', padding:'0px 10px'}}>


               <div>
                    fleet:
                   <Dropdown options={fleets} optionLabel={"fleetName"} value={fleet} placeholder="Select a fleet"
                             onChange={(e) => setFleet(e.target.value)}/>

                   category:
                   <Dropdown options={catOptions} value={placeFilter}
                             onChange={(e) => setPlaceFilter(e.target.value)}
                             valueTemplate={(e) => optionTemplate(e, 'desktop', "Filter")}
                             itemTemplate={(e) => optionTemplate(e, 'desktop', "Filter")}
                             placeholder="Filter" optionLabel="display"/>

                   {placeFilter &&
                    <button onClick={() => setPlaceFilter(null)}>Reset cat</button>
                   }

               </div>


                <Button onClick={() => openShowPlaceModal()} className="p-button-sm"
                        style={{background: 'var(--v-list-button-selected)', color: 'white'}}
                        label={<span><FontAwesomeIcon icon={faLocationPlus} className="p-button-label" style={{fontSize: '14px'}}/>&nbsp;Add place</span>}
                        tooltip="Add new place of interest" tooltipOptions={{position:"bottom"}}
                        disabled={!fleet}/>
            </div>




                <div style={winWidth > 800 ? {width: '100%', height: '100%'} : {width: '100%', height: '100%'}}>

                    {gridData?.length === 0 && fleet && <NoDataMessage showVehicleButton={false}/>}


                    {(gridData?.length > 0) &&
                    <DataScroller value={gridData} itemTemplate={GridRow} rows={12} inline
                                  scrollHeight={winWidth > 800 ? "77vh" : "84vh"} className="scrollerTest"  />

                    }
                </div>



            <PlaceModalParent showPlaceModal={showPlaceModal} placeSelected={placeSelected} handleShowPlaceModal={handleShowPlaceModal}
                               handleRefreshPois={handleRefreshPois} fleetId={placeSelected?.fleetId} />


        </React.Fragment>
    )
}

export default Places
