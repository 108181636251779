import React, {useContext} from 'react';
import {Dialog} from "primereact/dialog";
import indexContext from "../../../contexts/indexContext";
import PlaceModal from "./placeModal";
import {deepCompare} from "../../../../functions/deepCompare";
import postDeletePoi from "../../../../api/places/postDeletePoi";
import postAddPoi from "../../../../api/places/postAddPoi";
import {TOAST_DURATION} from "../../../../Constants";
import mainContext from "../../../contexts/mainContext";

const PlaceModalParent = ({placeSelected, showPlaceModal, handleShowPlaceModal, handleRefreshPois, fleetId}) => {

    const {winWidth} = useContext(indexContext);
    const {toast} = useContext(mainContext);



    const saveChanges = async (val, ogVal) => {

        console.log(val)

        if (ogVal){
            const copy = JSON.parse(JSON.stringify(val))
            copy.location = {type: 'Point', coordinates: [copy.lng, copy.lat]}
            delete copy.lat;
            delete copy.lng;
            delete ogVal._id

            if (!deepCompare(copy, ogVal )){
                await postDeletePoi(ogVal);
                await postAddPoi(val);
                handleRefreshPois(true);
                handleShowPlaceModal(false);
                toast.current.show({ severity: 'success', summary: 'All done', detail: 'POI successfully updated',
                    life: TOAST_DURATION });
            } else {
                handleShowPlaceModal(false);
                toast.current.show({ severity: 'info', summary: 'Not updated', detail: 'No changes to be made', life: TOAST_DURATION });
            }
        } else {
            await postAddPoi(val);
            handleRefreshPois(true);
            handleShowPlaceModal(false);
            toast.current.show({ severity: 'info', summary: 'Not updated', detail: 'No changes to be made', life: TOAST_DURATION });

        }
    }


    return (
        <Dialog visible={showPlaceModal} style={{width: winWidth >= 800 ? '35vw' : '100vw',
            height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%'}} position={"right"}
                onHide={() => {
                    handleRefreshPois(false);
                    handleShowPlaceModal(false)
                }} >
            <div style={{marginTop: '-50px', position: 'absolute', fontWeight: 'bold', fontSize: '16px', width: '250px'}}>

                {placeSelected?._id ? `Editing ${placeSelected?.name}` : "Adding place of interest"}


                {/*<div style={{fontWeight: 'normal', marginBottom: '20px'}}>*/}
                {/*    {formatDateModalHeader(videoToShow?.st ? videoToShow?.st : videoToShow?.startTimeHyper)}*/}
                {/*    <br />*/}
                {/*    <span style={{fontSize: '1rem'}}>*/}
                {/*            <FontAwesomeIcon icon={faBuildings} style={{marginRight: '7px'}}/>*/}
                {/*        {friendlyFleetName(fleetFromDn(vehicles, videoToShow?.dn), fleets)}*/}
                {/*        </span>*/}
                {/*</div>*/}


            </div>



            {/*<VideoPlayer video={videoToShow} />*/}
            <div style={{}}>
                <PlaceModal placeSelected={placeSelected} saveChanges={saveChanges} handleRefreshPois={handleRefreshPois}
                            fleetId={fleetId} handleShowPlaceModal={handleShowPlaceModal}/>
            </div>
        </Dialog>
    )
}
export default PlaceModalParent
