import {faBuildings, faHouse, faBoxesStacked, faBan, faGasPump, faChargingStation, faUser, faShop} from "@fortawesome/pro-regular-svg-icons";

export const catOptions = [
    {
        icon: faChargingStation,
        display: "Charging station"
    },
    {
        icon: faUser,
        display: "Customer"
    },
    {
        icon: faBoxesStacked,
        display: "Depot"
    },
    {
        icon: faChargingStation,
        display: "Charging station"
    },
    {
        icon: faGasPump,
        display: "Fuel station"
    },
    {
        icon: faBuildings,
        display: "Office"
    },
    {
        icon: faHouse,
        display: "Residence"
    },
    {
        icon: faShop,
        display: "Supplier"
    },
    {
        icon: faHouse,
        display: "Residence"
    },

    {
        icon: faBan,
        display: "Restricted"
    }
]